<template>
  <div class="public_detail">
    <header>
      <div class="header_content">
        <div class="breadcrumb" v-if="locationP != '河南省'">
          <span class="name">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/train?active=second' }"
            >公开课
            </el-breadcrumb-item
            >
            <el-breadcrumb-item>详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="info flex">
          <div class="left">
            <h1>{{ detail.trainName }}</h1>
            <div class="flex">
              <p><span>发布时间：</span>{{ detail.createDate }}</p>
              <p><span>课程提供：</span>{{ detail.fwjgname }}</p>
            </div>
          </div>
          <div class="right">
            <div class="share relative">
              <span
              ><svg
                  t="1596004592776"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="4155"
                  aria-label="手机观看"
              >
                  <path
                      d="M332.313646 0c-48.985949 0-88.909996 39.805081-88.909996 88.811497l0 846.353981c0 48.985949 39.924047 88.834523 88.909996 88.834523l380.974676 0c48.900242 0 88.802542-39.848574 88.802542-88.834523L802.090864 88.811497c0-49.006416-39.901021-88.811497-88.802542-88.811497L332.313646 0zM748.086586 845.283284 297.45142 845.283284 297.45142 178.716716l450.633887 0L748.085307 845.283284zM476.897284 930.779077c0-25.316784 20.565809-45.926086 45.818632-45.926086 25.392257 0 46.065519 20.609302 46.065519 45.926086 0 25.40249-20.673263 46.055286-46.065519 46.055286C497.461814 976.834363 476.897284 956.181567 476.897284 930.779077zM566.91891 89.9679c5.799924 0 10.485659 4.665268 10.485659 10.443445 0 5.650257-4.685735 10.315524-10.485659 10.315524l-88.246087 0c-5.714217 0-10.443445-4.663988-10.443445-10.315524 0-5.778178 4.729228-10.443445 10.443445-10.443445L566.91891 89.9679z"
                      p-id="4156"
                  ></path></svg
              >手机观看 <i class="el-icon-arrow-down"></i
              ></span>
              <div class="shares">
                <p>扫一扫下载APP</p>
                <el-image :src="require('@/static/public/apps.png')" class="or" alt="扫一扫下载APP">
                  <div slot="placeholder" class="image-slot">
                    <img src="@/static/public/apps.png" alt="扫一扫下载APP"/>
                  </div>
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </div>
            </div>
          </div>
        </div>
        <div class="video">
          <div class="video_left" @contextmenu.prevent="handleDel($event)">
            <video-player
                class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOptions"
                @play="onPlayerPlay($event)"
                @pause="onPlayerPause($event)"
                @ended="onPlayerEnded($event)"
                @timeupdate="onPlayerTimeupdate($event)"
                @ready="playerReadied"
                title="视频"
            ></video-player>

            <!--@ended="onPlayerEnded($event)"-->
            <!--@loadeddata="onPlayerLoadeddata($event)"-->
            <!--@waiting="onPlayerWaiting($event)"-->
            <!--@playing="onPlayerPlaying($event)"-->
            <!--@timeupdate="onPlayerTimeupdate($event)"-->
            <!--@canplay="onPlayerCanplay($event)"-->
            <!--@canplaythrough="onPlayerCanplaythrough($event)"-->
            <!--@ready="playerReadied"-->
            <!--@statechanged="playerStateChanged($event)"-->
            <div class="tools flex">
              <div class="left">
                <span class="number">{{ detail.hits }}人在学</span>
                <!--                <span class="share">分享给朋友：</span>-->
                <!--                <img src="@/static//public/qq.svg" alt="" class="qq img">-->
                <!--                <img src="@/static//public/sina.svg" alt="" class="sina img">-->
                <!--                <img src="@/static//public/wechat.svg" alt="" class="wechat img">-->
                <!--                <img src="@/static//public/friends.svg" alt="" class="friend img">-->
              </div>
              <div class="right" @click="collects(detail.id, detail.trainName)">
                <i
                    :class="
                    detail.collect ? 'el-icon-star-on' : 'el-icon-star-off'
                  "
                ></i
                >{{ detail.collect ? "已收藏" : "收藏" }}
              </div>
            </div>
          </div>
          <ul class="video_right">
            <li
                v-for="(n, i) in list"
                :key="i"
                v-show="n.cousetype == 3"
                @click="play(n)"
                :class="{ active: actives == n.id }"
            >
              <div class="name line_clamp1">
                <span>录播</span>
                {{ n.filename }}
              </div>
              <p>{{ getTime(n.filesize) }}</p>
            </li>
          </ul>
        </div>
      </div>
    </header>
    <div class="content flex">
      <div class="content_left">
        <ul class="tab flex">
          <li :class="{ active: active == 0 }" @click="active = 0">课程详情</li>
          <li :class="{ active: active == 1 }" @click="active = 1">课程目录</li>
        </ul>
        <div class="text" v-if="active == 0" v-html="detail.content"></div>
        <ul class="list" v-if="active == 1">
          <li v-for="(n, i) in coursewareList" :key="i">
            <h1>{{ n.filename }}</h1>
            <div class="flex">
              <div class="name">
                <img
                    src="@/static/train/pdf.svg"
                    alt=""
                    v-if="n.cousetype == 0"
                    class="play_img"
                /><span class="play" v-else
              ><i class="el-icon-caret-right"></i
              ></span>
                {{ n.cousetype == 3 ? "【录播】" : "" }}{{ n.filename }}
                <span class="time" v-if="n.cousetype == 3"
                >（{{ getTime(n.filesize) }}）</span
                >
              </div>
              <div class="learn" @click="play(n)" v-if="n.cousetype == 3">
                <a href="javascript:void(0);" class="color00">
                  <span>开始学习</span>
                </a>
              </div>
              <div
                  class="learn"
                  v-else
                  @click="getPdf(detail.id, detail.trainName, n)"
              >
                <a :href="n.picurl" target="_blank" class="color00">
                  <span>开始学习</span>
                </a>
              </div>
            </div>
          </li>
        </ul>
        <div class="pages" v-if="active == 1">
          <el-pagination
              background
              class="work_pagination"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="page.pageNum"
              :page-size="page.pageSize"
              layout="prev, pager, next, total, jumper"
              :total="page.total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="content_right">
        <div class="company" v-if="detail.fwjgid">
          <img
              :src="detail.fwjgLogo || ''"
              alt=""
              :title="detail.fwjgname"
              class="logo err_logo"
          />
          <h2>{{ detail.fwjgname }}</h2>
          <div class="text text_c">
            {{ detail.fwjgdes }}
          </div>
          <!--          <el-button class="url">-->
          <!--            <router-link :to="'/training/mechanismDetail?id='+detail.id" target="_blank">查看主页</router-link>-->
          <!--          </el-button>-->
        </div>
        <div class="company" v-else>
          <el-image :src="require('@/static/log_new.png')" class="logo" aria-hidden="true">
            <div slot="placeholder" class="image-slot">
              <img src="@/static/log_new.png" alt=""/>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <h2>平台运营团队</h2>
          <div class="text">
            兰州市残疾人就业创业网络服务平台是中国残疾人联合会为了支持和帮助残疾人就业求职和残疾人创业而搭建的“互联网+”官方平台，为残疾人和招聘企业提供残疾人求职招聘、职业培训、政策法规、创业指导、信息展示、残保金政策、问答咨询等服务
          </div>
        </div>
        <!-- <div class="ad relative">
          <img src="@/static/train/ad.png" alt="">
          <p class="type">
            广告
          </p>
        </div> -->
      </div>
    </div>
    <login-prompt
        :goShow="isHide"
        @close="handleClose"
        title="职位申请"
        text="登录后才可以收藏"
    ></login-prompt>
  </div>
</template>

<script>
import loginPrompt from "@/components/public/loginPrompt";

export default {
  name: "publicDetail",
  components: {
    loginPrompt,
  },
  data() {
    return {
      collect: false,
      active: 0,
      actives: "",
      detail: [],
      coursewareList: [],
      list: [],
      isHide: false,
      isLogin: "",
      loading: false,
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        id: "",
      },
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: true, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "", //url地址
          },
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      history: {},
      playTime: "",
      historyTime: "",
      locationP: "",
      isPlay:false,
    };
  },
  methods: {
    sayHi(e) {
      console.log(e, 123456)
    },
    async info() {
      this.loading = true;
      try {
        let res = await this.$api.publicDetailApi({id: this.$route.query.id});
        if (res.data.success) {
          this.loading = false;
          this.detail = res.data.data;
          if (localStorage.getItem("userType")) {
            this.isLogin =
                this.$store.state.userType ||
                JSON.parse(localStorage.getItem("userType"));
          }
          this.courseware(this.detail.id);
          this.videoList();
        }
      } catch (e) {
        this.loading = false;
      }
    },
    //课件列表
    async courseware(id) {
      this.loading = true;
      try {
        this.page.id = id || this.$route.query.id;
        let res = await this.$api.publicVideoApi(this.page);
        if (res.data.success) {
          this.loading = false;
          this.coursewareList = res.data.data.records;
          localStorage.setItem(
              "playHistory",
              JSON.stringify(this.coursewareList[0])
          );
          this.playerOptions.sources[0].src = this.coursewareList[0].picurl;
          this.actives = this.coursewareList[0].id;
          this.page.total = res.data.data.total;
          if (this.$router.query.seweareid) {
            this.actives = "";
            this.history = [];
          } else {
            this.playerOptions.sources[0].src = this.coursewareList[0].picurl;
            this.actives = this.coursewareList[0].id;
            this.history = this.coursewareList[0];
          }
        }
      } catch (e) {
        this.loading = false;
      }
    },
    //视频列表
    async videoList() {
      this.loading = true;
      try {
        let res = await this.$api.publicVideoApi({
          pageNum: 1,
          pageSize: 500,
          id: this.$route.query.id,
        });
        if (res.data.success) {
          this.loading = false;
          this.list = res.data.data.records;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    //收藏
    collects(id, name) {
      if (this.isLogin) {
        this.$api.publicCollectApi({id: id, trainName: name}).then((res) => {
          if (res.data.success) {
            this.detail.collect = !this.detail.collect;
            if (this.detail.collect) {
              this.$message.success(res.data.msg);
            } else {
              this.$message.error(res.data.msg);
            }
          }
        });
      } else {
        this.isHide = true;
      }
    },
    //视频初始化
    playerReadied(player) {
      if (this.historyTime) {
        player.currentTime(this.historyTime);
      }
    },
    //播放事件
    play(info) {
      console.log('播放视频，onPlayerPlay')
      localStorage.setItem("playHistory", JSON.stringify(info));
      this.history = info;
      this.actives = info.id;
      this.playerOptions.sources[0].src = info.picurl;
      this.onPlayerPlay();
      this.getPlay();
      document.body.scrollTop = document.documentElement.scrollTop = 180;
    },
    //组件播放
    onPlayerPlay(player) {
      console.log('播放视频，onPlayerPlay',player.cache_.isPlaying)
      this.history = JSON.parse(localStorage.getItem("playHistory"));
      this.isPlay = true;
      this.getPlay();
    },
    //记录时间
    onPlayerTimeupdate(player) {
      this.playTime = parseInt(player.cache_.currentTime);
      localStorage.setItem("playTime", this.playTime);
    },
    //播放暂停记录时间
    onPlayerPause(player) {
      this.isPlay = false;
      this.getPlay();
    },
    //播放完记录时间
    onPlayerEnded(player) {
      this.getPlay();
    },
    //键盘事件
    keyup(key) {
      var vol = 0.1;  //1代表100%音量，每次增减0.1
      var time = 10; //单位秒，每次增减10秒
      // 按向上键
      if (key.keyCode == 38) {
        this.player.cache_.lastVolume !== 1 ? this.player.cache_.lastVolume += vol : 1;
        this.player.volume(this.player.cache_.lastVolume);
      }
      // 按向下键
      if (key.keyCode == 40) {
        this.player.cache_.lastVolume !== 0 ? this.player.cache_.lastVolume -= vol : 1;
        this.player.volume(this.player.cache_.lastVolume);
      }
      // 按左键
      if (key.keyCode == 37) {
        this.player.cache_.currentTime !== 0 ? this.player.cache_.currentTime -= time : 1;
        this.player.currentTime(this.player.cache_.currentTime);
      }
      // 按右键
      if (key.keyCode == 39) {
        this.player.cache_.currentTime !== this.player.cache_.duration ? this.player.cache_.currentTime += time : 1;
        this.player.currentTime(this.player.cache_.currentTime);
      }
      // 空格键控制播放暂停
      if (key.keyCode == 32) {
        if (this.isPlay) { this.player.pause(); } else { this.player.play(); }
      }
    },
    //向后台发送记录时间
    getPlay() {
      let info = this.history;
      this.$api
          .publicHistoryApi({
            couseid: this.detail.id,
            trainName: this.detail.trainName,
            seweareid: info.seweareid || info.id,
            sewearename: info.filename || info.sewearename,
            cousetype: info.cousetype,
            picurl: info.picurl,
            alltime: info.filesize || info.alltime,
            currenttime: this.playTime,
          })
          .then((res) => {
          });
    },
    //pdf记录
    getPdf(id, name, info) {
      this.$api
          .publicHistoryApi({
            couseid: id,
            trainName: name,
            seweareid: info.id,
            sewearename: info.filename,
            cousetype: info.cousetype,
            picurl: info.picurl,
          })
          .then((res) => {
          });
    },
    //时间转换
    getTime(time, info) {
      var theTime = parseInt(time); // 需要转换的时间秒
      var theTime1 = 0; // 分
      var theTime2 = 0; // 小时
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60);
          theTime1 = parseInt(theTime1 % 60);
        }
      }
      var result = "";
      if (theTime > 0) {
        result = "" + parseInt(theTime) + "秒";
      }
      if (theTime1 > 0) {
        result = "" + parseInt(theTime1) + "分" + result;
      }
      if (theTime2 > 0) {
        result = "" + parseInt(theTime2) + "小时" + result;
      }
      console.log(result);
      return result;
    },
    //登录验证关闭
    handleClose() {
      this.isHide = false;
    },
    //历史回看
    async historyTimed() {
      try {
        this.history = [];
        let res = await this.$api.getUserLookDetailApi({
          couseid: this.$route.query.id,
          seweareid: this.$route.query.seweareid,
        });
        if (res.data.success) {
          this.history = res.data.data;
          this.playerOptions.sources[0].src = this.history.picurl;
          this.actives = this.history.seweareid;
          this.historyTime = this.history.currenttime;
        }
      } catch (e) {
      }
    },
    //分页
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.courseware();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
  //  禁止右键点击
    handleDel(event){
      console.log(event)
      event.preventDefault();
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    }
  },
  created() {
    this.info();
    // window.addEventListener("beforeunload", this.getPlay);
    if (this.$route.query.seweareid) {
      this.historyTimed();
    } else {
      this.playerOptions.sources[0].src = this.history.picurl;
      this.actives = this.history.seweareid;
      this.historyTime = localStorage.getItem("playTime");
    }
    //判断是否移动端
    this.$mobile(
        "https://lzmz.cdpee.org.cn/#/pages/train/public/detail?id=" +
        this.$route.query.id
    );
    this.locationP = localStorage.getItem("locationProvince");
  },
  mounted() {
    this.$emit("goRouter", "/train/");
    window.addEventListener('keydown', event => {
      this.keyup(event);
      event.preventDefault();
    })
  },
  destroyed() {
    window.removeEventListener('keydown', event => {
      console.log(event.value)
      event.preventDefault()
    })
  }
};
</script>

<style scoped lang="less">
@import "~assets/css/train.less";
</style>
